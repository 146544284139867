import { Dialog } from '@progress/kendo-react-dialogs';
import { Upload, UploadFileInfo, UploadFileStatus, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import endpoints from '../../services/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../reducers/account/selectors';
import { showNotification } from '../../actions/notifications';
import NotificationsTypes from '../../constants/notification';

const UploadFile = ({ onSubmit, onCancel, folderName, onFinishUpload }: any) => {
  const authToken = useSelector(getToken);
  const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "xls", "doc", "odt", "docx", "dot", "xlsx", "zip", "mkv", "cad", "psd", "mp4", "mov"];
  const dispatch = useDispatch();

  const onStatusChange = (event: UploadOnStatusChangeEvent): void => {
    const { response } = event;

    if (response && response.status === 200) {
      onSubmit(response.response);
      if (event.newState.every((item: UploadFileInfo) => item.status === UploadFileStatus.Uploaded))
      {
        onFinishUpload();
      }
      dispatch(showNotification({ message: 'Document uploaded.', type: NotificationsTypes.Success }));      
    } else {
      dispatch(showNotification({ message: 'Unable to upload the document.', type: NotificationsTypes.Error }));
      onCancel();      
    }
  };

  const onBeforeUpload = (event: UploadOnBeforeUploadEvent): void => {
    // eslint-disable-next-line no-param-reassign
    event.headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${authToken}` };
    event.additionalData = {
      folder: folderName
    }
  };

  return (
    <Dialog title={'Upload new document'} onClose={onCancel}>
      <p className='font-ligth' style={{ marginBottom: 10, maxWidth: '380px' }}>Allowed file types: {allowedExtensions.join(", ").toUpperCase()}.</p>
      <p className='font-ligth' style={{ marginBottom: 10 }}>Max. size {process.env.REACT_APP_MAX_DOCUMENTS_SIZE}MB</p>
      <Upload
        saveField={'file'}        
        multiple={true}
        batch={false}
        restrictions={{
          allowedExtensions: allowedExtensions,
          maxFileSize: parseInt(process.env.REACT_APP_MAX_DOCUMENTS_SIZE ?? "") * 1000000,
        }}
        defaultFiles={[]}
        withCredentials={false}
        onBeforeUpload={onBeforeUpload}
        onStatusChange={onStatusChange}
        saveUrl={`${process.env.REACT_APP_API_BASE_URL_DOCUMENTS}/${endpoints.documents.upload.replace("{appId}", process.env.REACT_APP_ID ?? '')}`}
      />
    </Dialog>
  );
};

export default UploadFile;
